import {useEffect} from 'react';
import {translate} from './translate.js'
var ua = navigator.userAgent.toLowerCase()
export const isMobile=(/iphone/.test(ua)) || (/android/.test(ua) || window.offsetWidth<768) // || (/ipad/.test(ua))
// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function(search, replace) 
{
  if (typeof this!=='string') return this
return this.split(search).join(replace);
}
export const encodeDataToURL=(data)=> 
{
return Object.keys(data).map(value => `${value}=${encodeURIComponent(data[value])}`).join('&');
 }
export const arr_menu=[
{name:translate('О нас'),a:'about'},
{name:translate('Как это работает?'),a:'how'},
/*{name:'Активность',a:'activity'},
{name:'Партнеры',a:'partners'},*/
{name:translate('Импакт Карта'),a:'map',link:"https://map.protone.app"},
{name:translate('Магазин Наград'),a:'store',link:"https://store.protone.app"},
];

export function open_menu(item) 
{
const external_menu=true;
//window.location.hash='#'+item
  if (typeof item.link!=='undefined') window.open(item.link);
  else if (typeof document.getElementById(item.a)==='undefined' || document.getElementById(item.a)===null) 
  	  if (!external_menu) window.location.href='/#'+item.a
  	  else window.open('https://protone.app/#'+item.a)
  else
   {
window.history.replaceState(null, null, document.location.pathname+'#'+item.a);
window.scrollTo({top: document.getElementById(item.a).offsetTop-80,behavior: "smooth"})
   }
}
 //for animation scroll of div
const easing=(x)=>1-Math.pow(1 - x, 4)
export function animateDivScroll(target, left, duration)
{ 
let pos,start,aFrame
const maxScroll=target.scrollWidth-target.clientWidth
const init=target.scrollLeft
const amountToScroll=init-left;
  function step(curtime)
   {
  if (start===undefined) start=curtime
const elapsed=curtime-start
const progress=elapsed/duration
const eProgress=easing(progress)
pos=init-amountToScroll*Math.min(eProgress,1)
target.scrollTo({left:pos})
  if (init!==maxScroll && target.scrollLeft===maxScroll)
   {
window.cancelAnimationFrame(aFrame)
return
   }
  if (elapsed<duration) aFrame =window.requestAnimationFrame(step);
    }
aFrame=window.requestAnimationFrame(step);
}

export function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
export const delay=(x)=> 
{
return new Promise((resolve, reject)=>
  {
setTimeout(() => {resolve('')}, x)
  });
}
 export const returnImgPath=(theme,img)=>
 {
let path='/i/black/'+img
  if (theme==='white_theme') path='/i/white/'+img
return path
 }
export const returnShareLink=({url,title,description,img,type})=>
{
url=encodeURIComponent(url)
title=encodeURIComponent(title)
img=encodeURIComponent(img)
  if (type==='telegram') return 'https://t.me/share/url?url='+url+'&text='+title;
  else if (type==='wa') return 'https://api.whatsapp.com/send?text='+url+' '+title;
  else if (type==='odnoklassniki') return 'https://connect.ok.ru/offer?url='+url+'&title='+title+'&imageUrl='+img;
  else if (type==='vk') return 'https://vk.com/share.php?url='+url+'&title='+title+'&image='+img;
}