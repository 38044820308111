import './error.css';
import { useEffect, useState } from 'react'
import {translate} from '../translate.js'

const ErrorDialog = (props) => {
  const [stringError, setStringError] = useState()

  useEffect(() => {
    setStringError(props.error?typeof props.error === 'string'?props.error:JSON.stringify(props.error, null, 2):null)
  }, [props.error])

return (
  <div onClose={props.onClose} open={props.error} id="dialog_error" onClick={(e)=>{
  if (e.target.id==='dialog_error') props.onClose()
}} >
  	  <div className="dialog_error_wrapper">
<div className="dialog_error_close" onClick={()=>{props.onClose()}}><img src="/i/close_popup.svg" alt="" /></div>
<div className="dialog_error_title">{props.title}</div>
<div className="dialog_error_subtitle">
{translate('Произошло что-то плохое. Сайт может работать некорректно.')}
<br /><br />{translate('Сообщите детали ошибки команде поддержки.')}<br /><br />{translate('При нажатии кнопки детали ошибки будут скопированы, и открыт бот поддержки. Останется просто вставить их и отправить.')}
</div>
        <textarea id="dialog_error_text" readOnly value={window.location+"\n"+props.title+"\n"+stringError}></textarea>
  <button className="dialog_error_button" onClick={()=>
{
const copyText = document.getElementById('dialog_error_text')
copyText.select()
document.execCommand('copy')
window.open("https://t.me/protone_support_bot","_blank")
props.onClose()
}}>Написать нам</button>
		</div>
    </div>
  )
}
//<br /><br />Скопируйте детали ошибки и отправьте их команде поддержки, просто нажав на кнопку ниже.
export default ErrorDialog
