function popupReducer(state,action)
{
  switch (action.type) {
    case 'set_visible':
    {
      if (state.visible===action.visible) return state
      else return {...state, visible:action.visible}
     }
    case 'switch_visible':
     {
return {...state, visible:!state.visible}
     }
    case 'set_width':
     {
return {...state, width: action.width}
     }
    case 'set_all':
     {
return {...state, bottom: action.bottom, left: action.left, width: action.width, anim:action.anim, visible:action.visible, tip:action.tip,filter:action.filter}
     }
    case 'set_animation':
    {
return {...state, visible:(typeof action.visible!=='undefined')?action.visible:state.visible, anim: action.anim}
     }
    case 'set_filter':
     {
return {...state, filter:action.filter}
     }
default: { throw new Error('Unknown action: '+action.type) }
  }
}

export default popupReducer;