import './main.scss';
import useErrorHandler from './error/useErrorHandler'
import {ThemeContext} from './App'
import {useState,useEffect,useRef,useReducer,createContext/*, useContext*/, forwardRef, useContext} from 'react';
import {setError,getGlobalPoints,getPoint/*,returnNamebyPointType*/,getFilters,getPartners,getAllPartners} from "./api";
import {animateDivScroll,useEvent,isMobile,delay, returnImgPath, returnShareLink} from './functions.js'
import {useParams} from "react-router-dom";
import {translate} from './translate.js'
import Images from "./image"
import popupReducer from "./popup_reducer"
import filterReducer from "./filter_reducer"
import menuReducer from "./menu_reducer"
import Footer from "./footer"
/*import Map from "./map"*/
var classNames = require('classnames');
const OurContext = createContext();
//const ImagesPath = '/i/';

const ApiMode=()=>
{
const h=window.location.hash
let s='';
  if (h==='') return '';
  else if (h==='#alpha') s='alpha'
  else if (h==='#beta') s='beta'
  else if (h==='#gamma') s='gamma'
  else if (h==='#delta') s='delta'
  else if (h==='#dev') s='dev'
return(
  <div className="api_mode">{s}</div>	
)
}

const Slider=forwardRef(function Slider(props,ref)
{
const {theme} = useContext(ThemeContext);
const thumbScroll=useRef(null);
const [current,setCurrent]=useState(parseInt(props.current))
const [fireArrow,setFireArrow]=useState({goLeft(){return false},goRight(){return false}})
const [showArrors,setShowArrays]=useState(false)
const [heightContainer,setHeightContainer]=useState(0)
const refWrapper=useRef(null)
const i_resize=(e)=>
{
const wh=window.innerHeight, ww=window.innerWidth
document.documentElement.style.setProperty('--app-height', wh+'px');
document.documentElement.style.setProperty('--app-width', ww+'px');
  if (refWrapper.current!==null && thumbScroll.current!==null)
   setShowArrays(!(refWrapper.current.offsetWidth>thumbScroll.current.offsetWidth))
  if (ref.current!==null && isMobile)
   {
const el=ref.current.getElementsByClassName('menu_sticky')
  if (el.length>0 && el[0]!==null) setHeightContainer(window.innerHeight-el[0].offsetHeight-16)
   }
}
//add handler for resize
useEvent('resize', i_resize)
useEffect(() => {
i_resize() // eslint-disable-next-line react-hooks/exhaustive-deps
},[thumbScroll.current])
useEffect(() => {
if (thumbScroll.current===null) return
const scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-25
/*thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "smooth"
});*/
  if (scrollTo<thumbScroll.current.scrollWidth-thumbScroll.current.offsetWidth || thumbScroll.current.scrollLeft===0  || current===props.aimg.length-1) 
   {
  animateDivScroll(thumbScroll.current,scrollTo,1000)
   }
props.apiEventsSend({event_name:"impact_map_element_picture_click"}); // eslint-disable-next-line react-hooks/exhaustive-deps
}, [current]);
useEffect(() => {
window.setTimeout(function(){setCurrent(parseInt(props.current));},100) // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);
  if (props.show===false || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
//   thumbs.push(<img  src={props.aimg[i].preview} alt="" key={"thumb_"+i} onClick={(e)=>{(e).stopPropagation();setCurrent(parseInt(temp))}} className={classNames("image",{"image_act":(current===i)?true:false})} />)
   thumbs.push(<div style={{backgroundImage:"url('"+props.aimg[i].preview+"')"}} alt="" key={"thumb_"+i} onClick={(e)=>{(e).stopPropagation();setCurrent(parseInt(temp))}} className={classNames("image",{"image_act":(current===i)?true:false})} />)
   }
//for buttons at container (center of picture instead of at thumbs*/
/*thumbs.push(<img alt="" src={returnImgPath(theme,"arrow_left.svg")} className="arrow_left" onClick={()=>fireArrow.goLeft()} />)
thumbs.push(<img alt=""src={returnImgPath(theme,"arrow_left.svg")}  className="arrow_right" onClick={()=>fireArrow.goRight()} />)*/
//
const scroller=(e)=>
{
let scrollTo;
e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
//animateDivScroll(thumbScroll.current,scrollTo,1000) //for smooth aimation, disabled by default
}

const closeMe=()=>
{
props.close()
}
const topMobile=(isMobile)?<div className="menu_top menu_sticky">
		<div className="wrapper">
<img src={(!isMobile)?returnImgPath(theme,"back.svg"):returnImgPath(theme,"back_mobile.svg")} alt="" className="back" onClick={()=>closeMe()} />
<div className="top_title">{props.point.name}</div>
		</div>
		<div className="menu_hr menu_hr_point"></div>
	</div>:''
return(
<div className="wrapper_gallery main_slider" ref={ref}  onClick={(e)=>{/*console.log(e.target.className); */if (e.target.className.indexOf('wrapper_gallery')!==-1 && !isMobile) closeMe()}} >
{topMobile}
				<div className="panel_gallery"  style={{height:heightContainer}}>
<div className="close" onClick={()=>closeMe()} />

<div className="slider_container"><Images current={current} setcur={(e)=>{setCurrent(e)}} close={closeMe} arr={props.aimg} arrows={true} swipe={true} click="once" setArrow={setFireArrow} /></div>
		<div className="images_wrapper" ref={refWrapper}>
{(showArrors)?<img alt="" src={returnImgPath(theme,"arrow_left.svg")}  className="arrow_left" onClick={()=>fireArrow.goLeft()} />:''}
{showArrors?<img alt="" src={returnImgPath(theme,"arrow_left.svg")}   className="arrow_right" onClick={()=>fireArrow.goRight()} />:''}
			<div className="images" ref={thumbScroll} onWheel={(e)=>{scroller(e);}}>
{thumbs}
			</div>
		</div>
				</div>
</div>
)
})

const Filter=(props)=>
{
const [isShow,setIsShow]=useState(false)
let icon='';
  switch (props.title)
   {
    case 'Утилизация':
    {
icon='/i/icon_util.svg';
break
    }
    case 'Арт-объекты':
    {
icon='/i/icon_art.svg';
break
    }
    case 'Знания': 
    {
icon='/i/icon_know.svg';
break
    }
    case 'Помощь животным': 
    {
icon='/i/icon_pets.svg';
break
    }
    default: { icon='/i/icon_know.svg';}
   }
const toggle_all=()=>
{
let rez=[]
props.arr.map((item)=>rez.push(item.filterId))
props.set({type:'toggle_all',ids:rez})	
}
    useEffect(() => {
let allId=[]
props.arr.map((item)=>allId.push(item.filterId))
  if (props.active.filter(item => !allId.includes(item)).length===props.active.length) setIsShow(true)
  else setIsShow(false) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.active])
return(
	<div className="menu_wrapper">
		<div className="wrapper">
		<div className="top">
			<img src={icon} alt="" />
			<div className="title">{translate(props.title)}</div>
		</div>
	<div className="show" onClick={toggle_all}>{(isShow)?translate("Показать все"):translate("Скрыть все")}</div>
		</div>
		<div className="items">
{props.arr.map((item,i)=>{
return <div className={classNames("item",{"item_act":(props.active.indexOf(item.filterId)!==-1)?true:false})} id={item.filterId} key={i} onClick={()=>props.set({type:'toggle',id:item.filterId})}>{translate(item.title)}</div>
})}
		</div>
	</div>
)
}

const Filters=(props)=>
{
//const list = Object.keys(props.arr);
const arr=props.arr
const buttonApprove=<div className={classNames("menu_button",{"menu_button_act":(props.active.length>0)?true:false})}  onClick={props.clickHandler}>{translate("Применить")}</div>
let rez=[]
  for (let property in arr) 
   {
  rez.push(<Filter key={property} title={translate(property)} arr={arr[property].points} active={props.active} set={props.set} />)
  if (isMobile) rez.push(<div key={"mobile_"+property}>{(isMobile)?buttonApprove:''}</div>)
   }
return (
<div>
{rez}
{(!isMobile)?buttonApprove:''}
</div>
)
}

const Partner=(props)=>
{
const [isShow,setIsShow]=useState(true)
/* //если в дальнейшем захотим добавить множественный фильтр
const toggle_all=()=>
{
let rez=[]
props.arr.map((item)=>rez.push(item.partnerId))
props.set({type:'toggle_all',ids:rez})	
}
    useEffect(() => {
let allId=[]
props.arr.map((item)=>allId.push(item.partnerId))
  if (props.active.filter(item => !allId.includes(item)).length===props.active.length) setIsShow(true)
  else setIsShow(false)
  }, [props.active])
 */
return(
	<div className="menu_wrapper">
		<div className="wrapper wrapper_partner">
		<div className="top">
			<div className="title">{translate(props.title)}</div>
		</div>
	<div className="show"  onClick={()=>{setIsShow(!isShow);delay(100).then(()=>{if (document.getElementsByClassName('collapsed_mobile').length!==0) props.setFilter()})}}>{(!isShow)?translate("Показать фильтр"):translate("Скрыть фильтр")}</div>
		</div>
		<div className={classNames("items",{"hidden":!isShow})}>
{props.arr.map((item,i)=>{
return <div className={classNames("item",{"item_act":(props.active.indexOf(item.partnerId)!==-1)?true:false})} id={item.padrtnerId} key={i} onClick={()=>{props.set({type:'toggle',id:item.partnerId});props.setPartnersFire(Math.random())}}>{item.title}</div>
})}
		</div>
	</div>
)
}

const Partners=(props)=>
{
const arr=props.arr
let rez=[]
  for (let property in arr) 
   {
  rez.push(<Partner key={property} title={property} arr={arr[property].points} active={props.active} set={props.set} setPartnersFire={props.setPartnersFire} setFilter={props.setFilter} />)
   }
return (
<div>
{rez}
</div>
)
}
const PointPanelItem=(props)=>
{
const title=(typeof props.title=='undefined' || props.title===null)?'':props.title
const address=(typeof props.address=='undefined' || props.address===null)?'':props.address
const img=(typeof props.img=='undefined' || props.img===null)?'':<img src={props.img} alt="" />
const count=(typeof props.count=='undefined' || props.count===null || props.count===0)?translate('Нет фотографий'):props.count+translate(' фотографий')
const work=(typeof props.work=='undefined' || props.work===null)?'':props.work
return(
<div className="point_list_item" data-ident={props.id} onClick={(e)=>{props.setCity({longitude:props.latitude,latitude:props.longitude,zoom:17});props.set(e)}}>
	<div className="image">
{img}
	</div>
	<div className="wrapper">
{		(title!=='')?<div className="title">{props.title}</div>:''}
{		(address!=='')?<div className="address" title={props.address}>{props.address}</div>:''}
		<div className="bottom">
			<div className="work">{translate(work)}</div>
			<div className="count">{count}</div>
		</div>
	</div>

</div>
)
}
const PointsPanel=(props)=>
{
const arr=props.arr
let rez=[]
  for (var i=0; i<arr.length; i++) 
   {
  rez.push(<PointPanelItem setCity={props.setCity} longitude={arr[i].longitude} latitude={arr[i].latitude} count={arr[i].countContent} key={i} title={arr[i].description} address={(arr[i].address)?arr[i].address:arr[i].name} work={arr[i].work} img={arr[i].img} set={props.set} id={arr[i].id} />)
   }
return (
<div className="point_list">
{rez}
</div>	
)
}

function Screen1(props)
{
const {theme} = useContext(ThemeContext)
const openScreen2=()=>
{
  if (props.currentPointsType==='undefined' || props.currentPointsType==='null')
   {
alert(translate('Попробуйте позже, карта еще не загружена'))
return false
   }
const handlerPoints=(e)=>
{
props.setCurrentPoints(null)
props.setGpoints(e)
delay(300).then(()=>props.setUpdateNow(Math.random()))
}
props.setCurrentPoints(null)
getGlobalPoints(handlerPoints,props.filters.items,null,props.arrAllFilters)
//getGlobalPoints(props.setGpoints,props.filters.items,null,props.arrAllFilters)
props.getPartners(props.setArrPartners,props.filters.items)
props.setMenu({type:'show_partners'})
  if (isMobile) delay(100).then(()=>props.setPosition(props.refMenu2,'bottom'))
	
}
const top_desktop=<div className="wrapper">
		<a href="https://protone.app" rel="noreferrer"><img src={returnImgPath(theme,"logo.svg")} alt="Protone" className="logo" /></a>
		<img src={returnImgPath(theme,"close.svg")} alt="" className="close" onClick={props.setClose} />
		</div>
const top_mobile=<div className="wrapper">
<div className="collapsed_hr"></div>
<img src={returnImgPath(theme,"down_mobile.svg")} alt="" className="down_mobile" onClick={props.setClose} />
<div className="title">{translate('Фильтр локаций')}</div>
		</div>
return(
<div>
<div className="menu_top menu_sticky">
{isMobile?top_mobile:top_desktop}
		<div className="menu_hr"></div>
		<div className="mobile_bottom_separator"></div>
	</div>
	<div className="menu_top menu_top_title">
		{(!isMobile)?<div className="title">{translate('Фильтр локаций')}</div>:''}
	</div>	
{(props.arrFilters!==false)?<Filters arr={props.arrFilters} active={props.filters.items} set={props.setFilters} clickHandler={openScreen2} />:''}
{/*<div className={classNames("menu_button",{"menu_button_act":(props.filters.items.length>0)?true:false})}  onClick={openScreen2}>{translate("Применить")}</div>*/}
<div className="menu_hr menu_hr_bottom"></div>
<Footer />
</div>
	)
}

function Screen2(props)
{
const {theme} = useContext(ThemeContext);
let arr_points=props.currentPoints
//  if (arr_points!==null && arr_points.length===0) arr_points=null
  if (arr_points!==null) arr_points.forEach(aPoint=>
{
const partner = props.arrAllPartners.find(item => item.partnerId === aPoint.partnerId)
aPoint.img=(typeof partner!=='undefined') ?partner.img:'/i/temp_partner.png'
})
/*
const arr_points=[
{id:1,img:'/i/temp_partner.png',name:'Точка приема батареек в магазине "Комус"',description:'Можайское шоссе 9, офис 92',work:'Круглосуточно',countContent:1025},
{id:2,img:'/i/temp_partner.png',name:'Пункт переработки N1"',description:'Ленинградский проспект, д. 10 стр. 2, офис 345, восьмая дверь налево',work:'09:00 - 22:00',countContent:null},
]*/
const openScreen1=()=>
{
//getGlobalPoints(props.setGpoints,props.filters.items,null,props.arrAllFilters)
props.setFilters({type:'init',arr:[]}) //очистим фильтры
getGlobalPoints(props.setGpoints,null,null,props.arrFilters)
props.setMenu({type:'show_filters'})
if (isMobile) props.setClose()
}
const list = Object.keys(props.arrPartners);
let currentFilter
  if (props.filters.items.length===1)
   {
currentFilter=props.arrFilters.filter(item=>props.filters.items[0]===item.filterId)
currentFilter=translate(currentFilter[0].owner)+': '+translate(currentFilter[0].title)
   }
   else
   {
currentFilter=translate('Фильтры')+` (${props.filters.items.length})`
   }
const openPoint=(e)=>
{
const point=arr_points.find(point => parseInt(point.id) ===parseInt(e.currentTarget.dataset.ident))
props.showPointPopup(e,parseInt(e.currentTarget.dataset.ident),point.mergedIds);props.setMenu({type:'show_point'})
window.history.replaceState( {},'','/point/'+point.id)
delay(300).then(()=>{
const elems=document.getElementsByClassName('map_selected')
  if (elems.length>0)  Array.from(elems).forEach((item)=>item.classList.remove('map_selected'))
  if ( document.getElementById('point_'+point.id)!==null && typeof document.getElementById('point_'+point.id)!=='undefined') document.getElementById('point_'+point.id).className="map_selected";
})
//window.history.replaceState( {},'','/point/'+point.id) //at iphone not work, if on this position
}
const handlerPartners=(e)=>
{
//console.log('handlerPartners')
props.setCurrentPoints(null)
props.setPartnersFire(props.partners.items)
//delay(100).then(()=>props.setUpdateNow(Math.random()))
}
const top_desktop=<div className="wrapper">
<img src={(!isMobile)?returnImgPath(theme,"back.svg"):returnImgPath(theme,"back_mobile.svg")} alt="" className="back" onClick={openScreen1} />
<div className="top_title top_title_filter">{currentFilter}</div>
<img src={returnImgPath(theme,"close.svg")}  alt="" className="close" onClick={props.setClose} />
		</div>
const top_mobile=<div className="wrapper">
<div className="collapsed_hr"></div>
<img src={returnImgPath(theme,"down_mobile.svg")} alt="" className="down_mobile_partners" onClick={props.setDown} />
<div className="top_title top_title_filter">{currentFilter}</div>
<img src={returnImgPath(theme,"close.svg")}  alt="" className="close" onClick={openScreen1} />
		</div>
return(
<div>
	<div className="menu_top menu_sticky">
{isMobile?top_mobile:top_desktop}
{(props.arrPartners!==false && typeof props.arrPartners[list[0]]!=='undefined' && typeof props.arrPartners[list[0]].points!=='undefined' && props.arrPartners[list[0]].points.length!==0)?<Partners setFilter={()=>(isMobile)?props.setDown():false} setPartnersFire={handlerPartners} arr={props.arrPartners} active={props.partners.items} set={props.setPartners} />:''}
		<div className="menu_hr menu_hr_partner"></div>
	</div>
	<div className="menu_top menu_top_title"> 
{(arr_points===null)?<div className="loading"></div>:<PointsPanel set={(e)=>{openPoint(e)}} arr={arr_points}  setCity={props.setCity} />}
	</div>	

</div>
	)
}

function ScrollableDivWithScroll(props)
{
const scrollableDivRef = useRef();
const [isDragging, setIsDragging] = useState(false);
const [startX, setStartX] = useState(0);

const handleMouseDown = e => {
  if ('PointerEvents' in window) return true
    setIsDragging(true);
    setStartX(e.clientX);
const all=scrollableDivRef.current.querySelectorAll('*')
for (var i=0; i<all.length; i++ ){all[i].classList.add('no_pointer')}
  };

 const handleMouseUp = () => {
  if ('PointerEvents' in window) return true
    setIsDragging(false);
const all=scrollableDivRef.current.querySelectorAll('*')
for (var i=0; i<all.length; i++ ){all[i].classList.remove('no_pointer')}
  };

 const handleMouseMove = e => {
  if ('PointerEvents' in window) return true
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
    scrollableDivRef.current.scrollTo({ left: scrollableDivRef.current.scrollLeft + deltaX, behavior: "smooth" });
  };

  return (
    <div className="one_point_filters_wrapper_top"  ref={scrollableDivRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
      <div className="one_point_filters_wrapper">
{props.items}
      </div>
    </div>
  );
}

function ShareLinks({url,title,img})
{
const {theme} = useContext(ThemeContext);
return(
<div className="shar_links">
	<a target="_blank" rel="noreferrer" href={returnShareLink({url:url,title:title,img:img,type:"telegram"})}><img src={returnImgPath(theme,"soc_tel.svg")} alt="Поделиться в Telegram" title="Поделиться в Telegram" /></a>
	<a target="_blank" rel="noreferrer" href={returnShareLink({url:url,title:title,img:img,type:"vk"})}><img src={returnImgPath(theme,"soc_vk.svg")} alt="Поделиться в ВКонтакте" title="Поделиться в ВКонтакте" /></a>
	<a target="_blank" rel="noreferrer" href={returnShareLink({url:url,title:title,img:img,type:"wa"})}><img src={returnImgPath(theme,"soc_wa.svg")} alt="Поделиться в WhatsApp" title="Поделиться в WhatsApp" /></a>
	<a target="_blank" rel="noreferrer" href={returnShareLink({url:url,title:title,img:img,type:"odnoklassniki"})}><img src={returnImgPath(theme,"soc_odn.svg")} alt="Поделиться в Одноклассники" title="Поделиться в Одноклассники" /></a>
</div>	
)
}

function Screen3(props)
{
const {theme} = useContext(ThemeContext);
	function SmallImages(props)
	{
const count=props.count; //max count of images
let i=0,rez=[]
const {arr}=props
  while (i!==count) 
   {
let temp=i
rez.push(<div className="image_block" key={"smallimage"+i} onClick={()=>props.setCur(temp)}><div className="image_block_image" style={{backgroundImage:"url('"+arr[i].preview+"')"}}  /></div>)
i++
   if (arr.length-1<i) break
   }
//add text information of count of images
let temp=i
if (arr.length>count) rez.push(<div className="image_block" key={"smallimage"+i}  onClick={()=>props.setCur(temp)}><div className="image_block_image"  style={{backgroundImage:"url('"+arr[i].preview+"')"}} />{(arr.length-count-1!==0)?<div className="text">+{arr.length-count-1}</div>:''}</div>)
//
return(
<div className="one_point_images">
{rez}
</div>
)
	}
  	function Work(props)
  	{
const arr=props.work.split('|');
let rez=[]
  for (var i=0; i<arr.length; i++) 
   {
const item=arr[i].split(': ');
rez.push(<div className="one_point_wrapper" key={i}>
				<div className="one_point_small_text">{translate(item[0])}</div>
				<div className="one_point_small_text">{translate(item[1])}</div>
			</div>
)
   }
return(
				<div className="one_point_top_wrapper">
		<div className="one_point_title_work">{translate("Часы работы:")}</div>
{rez}
				</div>
)
  	}
	function Partner(props)
	{
const item = props.partners.find(point => parseInt(point.partnerId) ===parseInt(props.id));
return(
			<div className="one_point_partner_wrapper one_point_top_wrapper one_point_wrapper">
				<img src={item.img} alt="" className="one_point_partner" />
<div className="one_point_big_text">{item.title}</div>
			</div>	
)
	}
	function returnTextDescription(s) //replace line break to html tags
	{
/* eslint-disable no-useless-escape */
  if (typeof s!='string') return ''
return s.replaceAll("/r/n","<br />").replaceAll("/n/r","<br />").replaceAll("/r","<br />").replace(/[\.|\; |:]([А-Я])/g, "<br /><br />$1");
	}

if (props.point===false || props.partners===false || props.filters===false) return <div className="loading"></div>;

let imgPoint='/i/temp_point.png'
const filters=props.point.filterIds.map((item,i)=>{
const filter=props.filters.find(el=> parseInt(el.filterId) ===parseInt(item));
  if (i===0) imgPoint=filter.img
//return <img src={filter.img} title={translate(filter.title)} alt={translate(filter.title)} className="one_point_filter" key={i}  />
return <div className="one_point_filter_img_wrapper" key={i+'w'}><img src={filter.img} title={translate(filter.title)} alt={translate(filter.title)} className="one_point_filter" key={i}  /><div>{translate(filter.title)}</div></div>
})
imgPoint=window.location.protocol+'//'+window.location.hostname+imgPoint
const address=(props.point.address===null)?'':<div className="one_point_top_wrapper one_point_title">{props.point.address}</div>
const openRoute=()=>
{
//const mOpen=window.open
const optionsGeo=
{
/*enableHighAccuracy: true*/
}
function success(pos)
{
pos=pos.coords;
const coord1=`${pos.latitude},${pos.longitude}`
const coord2=`${props.point.latitude},${props.point.longitude}`
//после долгих тестов во всех браузерах принято решение всегда переходить в том же окне
window.location.href=`https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`

//window.setTimeout(()=>window.open(`https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`),60)
//window.setTimeout(()=>mOpen(`https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`),20)
/*
var ua = navigator.userAgent.toLowerCase()
const isIos=(/iphone/.test(ua)) || (/ipad/.test(ua));  
  if (isIos) window.location.href=`https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`
  else window.open(`https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`)
*/
//so many code to fix Safari. i love Apple, in new version this  hack is not worked
/*const link=document.createElement('a');
link.id='temp_link';
window.document.body.appendChild(link);
const l=document.getElementById('temp_link');
l.setAttribute('href', `https://yandex.ru/maps/?rtext=${coord1}~${coord2}&rtt=auto`);
l.setAttribute('target', "_blank");
l.click();
window.setTimeout(()=>document.getElementById('temp_link').remove(),60)*/
//
}

function error(e)
{
alert(translate('Нельзя получить Ваши текущие координаты для постройки маршрута'))
}
    if (!navigator.geolocation)  error()
navigator.geolocation.getCurrentPosition(success, error, optionsGeo);

}
return(
<div>
	<div className="menu_top menu_sticky">
		<div className="wrapper">
<img src={(!isMobile)?returnImgPath(theme,"back.svg"):returnImgPath(theme,"back_mobile.svg")} alt="" className="back" onClick={props.setClose} />
<div className="top_title">{props.point.name}</div>
{/*<img src="/i/close.svg" alt="" className="close" onClick={props.setClose} />*/}
		</div>
		<div className="menu_hr menu_hr_point"></div>
	</div>
	<div className="menu_top one_point">
{(props.point.arrimg.length!==0)?<SmallImages arr={props.point.arrimg} count={2} setCur={props.setCur} />:''}
{address}
{ (props.point.phone===null)?'':
		<div className="one_point_top_wrapper one_point_wrapper">
		<div className="one_point_title_phone">{translate('Телефон:')}</div>
<a href={"tel:"+props.point.phone.replaceAll(' ','').replaceAll('(','').replaceAll(')','').replaceAll('-','')} className="one_point_text_phone">{props.point.phone}</a>
		</div>
}
{ (props.point.work===null)?"":<Work work={props.point.work} />}
{ (props.point.partnerId===null)?'':<Partner partners={props.partners} id={props.point.partnerId} />}
			<div className="one_point_top_wrapper one_point_filters">
			<div className="one_point_filters_title">{translate('Принимает на утилизацию')}</div>
	<ScrollableDivWithScroll items={filters} />
			</div>
{ (props.point.description===null || props.point.description==='')?'':
			<div className="one_point_top_wrapper">
				<div className="one_point_description_title">{translate('Описание')}</div>
				<div className="one_point_description_text" dangerouslySetInnerHTML={{__html: returnTextDescription(props.point.description)}}></div>
			</div>
}
<div className="menu_button menu_button_act" onClick={openRoute}>{translate('Построить маршрут')}</div>
			<div className="one_point_top_wrapper one_point_filters one_point_share">
			<div className="one_point_filters_title">{translate('Поделиться')}</div>
<ShareLinks url={window.location.href} title="Смотри какая точка есть на импакт-карте!" img={imgPoint} />
			</div>
	</div>	

</div>
	)
}

function Main(props)
{
const {theme} = useContext(ThemeContext);
//new
const [filters,setFilters]=useReducer(filterReducer,{items:[]})
const [arrFilters, setArrFilters]=useState(false)
const [arrAllFilters, setArrAllFilters]=useState(false)
const [partners,setPartners]=useReducer(filterReducer,{items:[]})
const [arrPartners, setArrPartners]=useState(false)
const [arrAllPartners, setAllArrPartners]=useState(false)
const [partnersFire,setPartnersFire]=useState(false)
const [menu,setMenu]=useReducer(menuReducer,{isClose:true,openPoint:false,openFilters:true,openPartners:false})
const [currentTop,setCurrentTop]=useState('bottom')
const refSlider=useRef(null) //for animate closing slider
const refMenu1=useRef(null)
const refMenu2=useRef(null)
const refMenu3=useRef(null)
const [firstInitPoint,setFirstInitPoint]=useState(false)
let {pointId } = useParams();
//end of new
const setVisibleError = useErrorHandler()
const [gpoints, setGpoints]=useState(false) //points for map
const pointsRef=useRef(null);
const filtersRef=useRef(null);
filtersRef.current=filters
const [apoints, setApoints]=useState(false) //array of current point, used for slider
const [popupPosition,setPopupPosition]=useReducer(popupReducer,{visible:false,bottom:0,left:0,width:0,tip:'',anim:false,filter:''})
const [touchStartY,setTouchStartY]=useState(0)
const [isDragging, setIsDragging] = useState(false)
//const popupRef=useRef(null)
//const pointScroll=useRef(null);
//const [showPointInfo,setShowPointInfo]=useState(false)


const [showSlider,setShowSlider]=useState(false)
const [current,setCurrent]=useState('0') //current  image for init slider
const [mobilePanel,setMobilePanel]=useState('init')
//const [showMobileHint,setShowMobileHint]=useState(false)

//api for events, yandex metrica
const apiEventsSend=(props)=>
{
const ua = navigator.userAgent.toLowerCase()
const isMobile=(/iphone/.test(ua))?'iOS':(/android/.test(ua))?'Android':'PC'
const defaultProps=
{
event_name:'No_set',
impact_task:(apoints!==false)?apoints.tip:translate('Нет данных'),
impact_theme:(apoints!==false)?apoints.topic:translate('Нет данных'),
impact_title:(apoints!==false)?apoints.name:translate('Нет данных'),
impact_description:(apoints!==false)?apoints.description:translate('Нет данных'),
/*current_city:(city!==false)?city.name:'Нет данных',*/
/*current_country:(city!==false)?city.fullName.split(', ')[0]:'Нет данных',*/
//current_task:(currentPointCategory!==false && points!==false)?returnNamebyPointType(points,currentPointCategory):'Все',
//current_theme:(topic!==false)?topic.name:'Нет данных',
device_type:isMobile
}
props=Object.assign({}, defaultProps, props);
const target=props.event_name
//for some events we use all of array, for another only part of array
let sendArr=(props.event_name.indexOf('impact_map_element')===-1)?{event_name:props.event_name,current_city:props.current_city,current_country:props.current_country,current_task:props.current_task,current_theme:props.current_theme,device_type:props.device_type}:props
delete sendArr.event_name
  if (typeof window.ym!=='undefined') window.ym(96208165, 'reachGoal', target, sendArr); 
//console.log(sendArr)
}
//end events for api

//for mobile menu touch*/
const handleTouchDown = (e, ref) => 
{
  if (e.target.className.indexOf('menu_sticky')!==-1 || e.target.parentNode.className.indexOf('menu_sticky')!==-1 || e.target.parentNode.parentNode.className.indexOf('menu_sticky')!==-1)
   {
//window.scrollTo(0,0)
//window.setTimeout(()=>i_resize(),30)
 Array.from(document.getElementsByClassName('menu')).forEach((item)=>item.classList.add('disable_scroll'))
setIsDragging(true); //ref.current.offsetTop
setTouchStartY(e.targetTouches[0].pageY);
   }
}
  const handleTouchUp = (e,ref) =>
{
    if (isDragging===false) return;
//window.scrollTo(0,0)
//window.setTimeout(()=>i_resize(),30)
 Array.from(document.getElementsByClassName('menu')).forEach((item)=>item.classList.remove('disable_scroll'))
 	 if (isDragging!==true)
 {
  if (isDragging.toString().indexOf('-')!==-1) 
   {
  	  setTop(ref,'top')
   }
  else  setTop(ref,'bottom')
}
    setIsDragging(false);
}
  const handleTouchMove = (e,ref) =>
 {
    if (isDragging===false) return;
const deltaY = parseInt(e.targetTouches[0].pageY - touchStartY);
setIsDragging(deltaY)
let rez=ref.current.offsetTop+deltaY
setTouchStartY(e.targetTouches[0].pageY);
  if (rez<0) rez=0
setTop(ref,rez);
//console.log(ref.current.offsetTop,deltaY)
   // scrollableDivRef.current.scrollTo({ left: scrollableDivRef.current.scrollLeft + deltaX, behavior: "smooth" });
}

/*end of mobile menu touch*/

const showPointPopup=(e,id,mergedIds)=> //show point information
{
  if (typeof e==='undefined' || e===null) return false
  	  //console.log(e,id,mergedIds)
getPoint(setApoints,parseInt(id),pointsRef.current,mergedIds,filtersRef.current.items)
setMenu({type:'show_point'})
/*window.setTimeout(function() {
setShowPointInfo(true)
setMobilePanel('point')
},1000)*/
}

const checkClick=(e)=> //for hide mobile panel, menu,...
{
var event = window.event ? window.event : e
let timer;
const cn=event.target.className;
//console.log(cn)
  if (cn!=='item' && cn.indexOf('select_main')===-1  && cn.indexOf('select_main_edit')===-1 && cn.indexOf('select_main_edit_image')===-1  && cn.indexOf('select_category')===-1  && cn.indexOf('select_input')===-1 && popupPosition.visible!==false) 
  {
 //setPopupPosition({type:'set_visible',visible:false});
setPopupPosition({type:'set_animation',anim:true,visible:false})
  window.clearTimeout(timer)
timer=window.setTimeout(()=>setPopupPosition({type:'set_animation',anim:false}),300);
}
  if (cn==='main main_mobile' || typeof cn==='undefined' || cn==='') 
   {
setMobilePanel(mobilePanel+'_collapse')
//setShowPointInfo(false)
   }
	
}

	
let init=false
let initRef=useRef(init)
useEffect(() =>
{ //init 
i_resize()
//setSize({w:window.innerWidth,h:window.innerHeight})
  if (initRef.current===false)  //in some situations React can fire event twice, in dev mode
   {
initRef.current=true
const itemsFilter=[];
setFilters({type:'init',arr:itemsFilter})
getFilters(setArrFilters)
//set list of all filters
getAllPartners(setAllArrPartners)
//getPoint(setApoints,1,pointsRef.current) //for debug

props.setPopup({showPointPopup})
//props.setClosePoint({setShowPointInfo})
const setDefaultLocation=()=>
{ 
if (typeof pointId==='undefined' || pointId===null) props.setCity({longitude:55.755819,latitude:37.617644,zoom:13}) //по-умолчанию Москва	
}
const setLocation=(pos)=>
{
  const crd = pos.coords;
if (typeof pointId==='undefined' || pointId===null) props.setCity({longitude:crd.latitude,latitude:crd.longitude,zoom:13})
}
    if (!navigator.geolocation) 
     { 
setDefaultLocation()
     }
     else
     { 
 navigator.geolocation.getCurrentPosition(setLocation, setDefaultLocation);
     }
    }
//default position of filters panel for mobile
setTop(refMenu1,'bottom')
window.setTimeout(()=>setTop(refMenu1,'bottom'),100)
setError(setVisibleError)
//setSize({w:window.innerWidth,h:window.innerHeight})
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
useEffect(() => { //get partners
setPartners({type:'init',arr:[]})
getPartners(setArrPartners,filters)
}, [filters]);

useEffect(() => { //get list of all filters
const arr=arrFilters
let rez=[]
  for (let property in arr) 
   {
arr[property].points.map((item)=>item.owner=property)
	
  rez=[...rez,...arr[property].points]
   }
  if (rez.length===0) rez=false
setArrAllFilters(rez)
}, [arrFilters]);
  
  useEffect(() => { // //get points for map  when application started
    if (arrAllFilters!==false) getGlobalPoints(setGpoints,null,null,arrAllFilters)
  }, [arrAllFilters]);
      useEffect(() => { //get points, when active partners is changed
//console.log('patners fire',partnersFire)
  if (partnersFire===false) return
 getGlobalPoints(setGpoints,filters.items,partners.items,arrAllFilters) // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [partnersFire])
/*
  useEffect(() => { //disable animation when filter
  if (popupPosition.anim===true)
   {
setPopupPosition({...popupPosition,anim:false})
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [popupFilter]);
*/
//update for map parent component
  useEffect(() => { 
  if (gpoints!==false)
   {
props.setGpoints(gpoints)
delay(100).then(()=>props.setUpdateNow(Math.random()))
	if (pointId && firstInitPoint===false) //после загрузки всех точек при необходимости покажем одну точку, нужно для роутинга
      {
const point=gpoints.find(point => parseInt(point.id) ===parseInt(pointId))
        if (point) 
        {
//console.log(point)
showPointPopup('init',parseInt(pointId),null);setMenu({type:'show_point'})
delay(10).then(()=>{props.setCity({longitude:point.latitude,latitude:point.longitude,zoom:17}) }) //покажем расположение точки 
delay(300).then(()=>{
  if ( document.getElementById('point_'+pointId)!==null && typeof document.getElementById('point_'+pointId)!=='undefined') document.getElementById('point_'+pointId).className="map_selected";
})
        }
setFirstInitPoint(true)
      } //конец одной точки
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [gpoints]);

/*useEffect(() => { //get ref of current popup position of menu
  if (popupPosition!==false)
   {
popupRef.current=popupPosition;
   }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [popupPosition]);*/
//end update for map parent component

//add handlers for check click (mobile)
//useEvent('click', checkClick)
useEvent('mousedown',checkClick)
//

const i_resize=(e)=>
{
const wh=window.innerHeight, ww=window.innerWidth
document.documentElement.style.setProperty('--app-height', wh+'px');
document.documentElement.style.setProperty('--app-width', ww+'px');
//setSize({w:ww,h:wh})
//window.setTimeout(()=>window.scrollTo(0,0),20)
  if (refMenu1.current!==null && isDragging===false && currentTop!=='0px')
setTop(refMenu1,'bottom')
  if (refMenu2.current!==null && isDragging===false && currentTop!=='0px')
setTop(refMenu2,'bottom')
  if (popupPosition.visible)
   {
    if (!isMobile)
     {
setPopupPosition({type:'set_animation',visible:false,anim:false})
document.activeElement.blur()
     }
     else //на самой мобильной версии это не нужно, но на десктопе тоже может быть маленький экран при ресайзе
      {
const temp_ref=document.getElementsByClassName('select_main_ref_mobile')
	  if (typeof temp_ref!=='undefined' && temp_ref.length>0 && temp_ref[0].offsetWidth) setPopupPosition({type:'set_width',width:temp_ref[0].offsetWidth})
      }
   }
}
//add handler for resize
useEvent('resize', i_resize)
//useEvent('scroll', i_resize)
//useEvent('touchmove', (e)=>{ e.preventDefault();e.stopPropagation();}) //prevent scroll on mobile
//useEvent('scroll', (e)=>{ e.preventDefault();e.stopPropagation();}) //prevent scroll on mobile
//
//open slider after click
const openSlider=(id)=>
{
	/*console.log(id)*/
setMobilePanel('slider')
//setShowPointInfo(false)
setCurrent(id)
setShowSlider(true)
window.parent.postMessage('map_slider_open','*')
}

const closeSlider=()=>
{
apiEventsSend({event_name:"impact_map_element_picture_close_click"});
refSlider.current.classList.add('wrapper_gallery_hide')
delay(300).then(()=>{
setShowSlider(false);
//refSlider.current.classList.remove('wrapper_gallery_hide')
setMobilePanel('point');
window.parent.postMessage('map_slider_close','*')
})

}

const closeMenu=(ref,isPoint)=>
{
//если есть активная точка на карте то снимем активность
const el=document.getElementsByClassName('map_selected')
  if (el.length>=1) 
   {
el[0].classList.add('map_div')
el[0].classList.remove('map_selected')
   }
//
ref.classList.add('menu_anim_hide');
    if (showSlider) closeSlider();
delay(300).then(()=>{
ref.classList.remove('menu_anim_hide');
  if (isPoint)
   {
setMenu({type:'close_point'})
   }
  else setMenu({type:'switch_close'})
	})
}
const setTop=(refMenu,px)=>
{
if (!isMobile) return false
/*  if (window.scrollTop>0) window.scrollTo(0,0)
  if (parseFloat(document.getElementsByClassName('menu')[0].scrollTop)>0)
   {
 Array.from(document.getElementsByClassName('menu')).forEach((item)=>item.scrollTop='0px')
   }*/
  if (typeof px==="string")
    {
let vis=false
      if (px==='top')
      {
px='0px'
vis=false
/*window.scrollTo(0,0)
i_resize()*/
       }
      if (px==='bottom')
       {
/*window.scrollTo(0,0)
i_resize()*/
px=(refMenu.current!==null)?window.innerHeight-refMenu.current.querySelector('.menu_sticky').offsetHeight/*-8*/+"px":'unset';
vis=true;
       }
 Array.from(document.getElementsByClassName('menu')).forEach((item)=>item.classList.add('menu_transition'))
      delay(300).then(()=>
       {
 Array.from(document.getElementsByClassName('menu')).forEach((item)=>item.classList.remove('menu_transition'))
setMenu({type:'set_close',visible:vis})
       })
    }
setCurrentTop(px)
//setCurrentTop((window.innerHeight-parseInt(px)-refMenu.current.offsetHeight)+'px')
}

  if (isMobile)
  {
return(
    <OurContext.Provider value={{popupPosition,setPopupPosition}}>
<div>
<img src={returnImgPath(theme,"logo_mobile.svg")} alt="" className="logo_mobile" />
	<div className="main">
<ApiMode />
{
(menu.openPoint)?
	<div className="menu menu_point"  ref={refMenu3}>
<Screen3 point={apoints} filters={arrAllFilters} partners={arrAllPartners}  setCur={openSlider} setClose={()=>closeMenu(refMenu3.current,true)} setMenu={setMenu}  />
	</div>
	:''
}
{
(menu.openFilters)?
	<div className={classNames("menu menu_filters",{"collapsed_mobile":menu.isClose})} ref={refMenu1} style={{top:currentTop}} onTouchStart={(e)=>handleTouchDown(e,refMenu1)} onTouchEnd={(e)=>handleTouchUp(e,refMenu1)} onTouchMove={(e)=>handleTouchMove(e,refMenu1)}>
<Screen1 setCurrentPoints={props.setCurrentPoints} setUpdateNow={props.setUpdateNow} refMenu2={refMenu2} setPosition={setTop} currentPointsType={typeof props.currentPoints} arrFilters={arrFilters} filters={filters} setFilters={setFilters} setClose={()=>setTop(refMenu1,'bottom')} setMenu={setMenu} setGpoints={setGpoints} getPartners={getPartners} setPartners={setPartners} setArrPartners={setArrPartners} arrAllFilters={arrAllFilters}  />
	</div>
	:''
}
{
(menu.openPartners)?
	<div className={classNames("menu menu menu_partners",{"collapsed_mobile":menu.isClose})}  ref={refMenu2} style={{top:currentTop}} onTouchStart={(e)=>handleTouchDown(e,refMenu2)} onTouchEnd={(e)=>handleTouchUp(e,refMenu2)} onTouchMove={(e)=>handleTouchMove(e,refMenu2)}>
<Screen2 setCurrentPoints={props.setCurrentPoints} setUpdateNow={props.setUpdateNow} setPartnersFire={setPartnersFire} showPointPopup={showPointPopup} setCity={props.setCity} currentPoints={props.currentPoints} arrAllPartners={arrAllPartners} arrPartners={arrPartners} partners={partners} setPartners={setPartners} setDown={()=>setTop(refMenu2,'bottom')} setClose={()=>{delay(100).then(()=>setTop(refMenu1,'bottom'))}} setMenu={setMenu} setGpoints={setGpoints} filters={filters} arrFilters={arrAllFilters} setFilters={setFilters}  />
	</div>
	:''
}
</div>
</div>
<Slider point={apoints} ref={refSlider} apiEventsSend={apiEventsSend} current={current} aimg={apoints.arrimg} show={showSlider}  close={closeSlider} title={apoints.tip} subtitle={apoints.name} height={1} />

</OurContext.Provider>
)

    }
    else
  return (
    <OurContext.Provider value={{popupPosition,setPopupPosition}}>
<div>
	<div className="main">
<ApiMode />
<div className="menu_closed">
	<img src={returnImgPath(theme,"menu.svg")} alt="" className="close_button" onClick={()=>setMenu({type:'switch_close'})} />
</div>
{
(menu.openPoint)?
	<div className="menu menu_point"  ref={refMenu3}>
<Screen3 point={apoints} filters={arrAllFilters} partners={arrAllPartners}  setCur={openSlider} setClose={()=>closeMenu(refMenu3.current,true)} setMenu={setMenu}  />
	</div>
	:''
}
{
(menu.openFilters && !menu.isClose)?
	<div className="menu menu_filters" ref={refMenu1}>
<Screen1 setCurrentPoints={props.setCurrentPoints} setUpdateNow={props.setUpdateNow} currentPointsType={typeof props.currentPoints} arrFilters={arrFilters} filters={filters} setFilters={setFilters} setClose={()=>closeMenu(refMenu1.current)}setMenu={setMenu} setGpoints={setGpoints} getPartners={getPartners} setPartners={setPartners} setArrPartners={setArrPartners} arrAllFilters={arrAllFilters}  />
	</div>
	:''
}
{
(menu.openPartners && !menu.isClose)?
	<div className="menu menu_partners" ref={refMenu2}>
<Screen2 setCurrentPoints={props.setCurrentPoints} setUpdateNow={props.setUpdateNow} setPartnersFire={setPartnersFire} showPointPopup={showPointPopup} setCity={props.setCity} currentPoints={props.currentPoints} arrAllPartners={arrAllPartners} arrPartners={arrPartners} partners={partners} setPartners={setPartners} setClose={()=>closeMenu(refMenu2.current)} setMenu={setMenu} setGpoints={setGpoints} filters={filters} arrFilters={arrAllFilters} setFilters={setFilters}  />
	</div>
	:''
}
</div>
</div>
<Slider  point={apoints}  ref={refSlider} apiEventsSend={apiEventsSend} current={current} aimg={apoints.arrimg} show={showSlider}  close={closeSlider} title={apoints.tip} subtitle={apoints.name} height={1} />

</OurContext.Provider>
  );
}
export default Main;