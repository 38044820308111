import './App.scss';
import {useState, useEffect, createContext} from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ErrorContainer from './error/ErrorContainer'
import Main from './main';
import Map from "./map"
import {isMobile} from './functions.js'
import {detectLanguage, setLanguage} from './translate.js'
export const ThemeContext = createContext();

function App()
{
//(localStorage.getItem('theme')===null)?'black_theme':localStorage.getItem('theme')
const getPreferredTheme=()=>
{
  if (localStorage.getItem('theme')===null) return window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'black_theme' : 'white_theme';
  else return localStorage.getItem('theme') 
}
const [theme,setTheme]=useState(getPreferredTheme());
const [lang,setLang]=useState(detectLanguage());
const domain=""
//const [mobile,setMobile]=useState('');
useEffect(() => {
//document.body.className=''
if (!document.body.classList.contains('body_animate')) window.setTimeout(()=>{document.body.classList.add('body_animate')},50)
//console.log(getPreferredTheme())
setTheme(getPreferredTheme)
  if (isMobile) document.body.classList.add('mobile_mode')
//���������� ����
//setLang(detectLanguage())
//
}, []);
useEffect(() => {
//  if  (document.body.className==='') document.body.className=theme;
//  else document.body.className="body_animate "+theme;
const listCls=document.body.classList
  listCls.forEach((el) =>
  {
    if ((el).indexOf('_theme')!==-1) document.body.classList.remove(el)
  })
document.body.classList.add(theme)
localStorage.setItem('theme',theme)
}, [theme]);
useEffect(() => {
setLanguage(lang)
}, [lang]);
const [city,setCity]=useState(false)
const [gpoints,setGpoints]=useState(false)
const [points,setPoints]=useState(false)
const [popupPoints,setPopup]=useState(null)
const [closePoint,setClosePoint]=useState(undefined)
const [currentPoints,setCurrentPoints]=useState(undefined) //������� ����� ��� ������, ������ ����� ��������� ����������
const [updateNow, setUpdateNow]=useState(0) //��� ������������ ���������� ����� �����, �������� ��� ������� ���������������� ������ ����� ��� ��������� ��������
const render=<ThemeContext.Provider value={{theme,setTheme,lang,setLang}}><ErrorContainer>
<Main lang={lang} setUpdateNow={setUpdateNow} setCity={setCity} setGpoints={setGpoints} setPoints={setPoints} setPopup={setPopup} setClosePoint={setClosePoint} currentPoints={currentPoints} setCurrentPoints={setCurrentPoints}   />
</ErrorContainer></ThemeContext.Provider>
  return (
    <div className="App">
<Map updateNow={updateNow}  x={city.latitude} y={city.longitude} zoom={city.zoom} arr={gpoints} icons={points} popup={(e,id,filterIds)=>popupPoints.showPointPopup(e,id,filterIds)} closePoint={closePoint} setCurrentPoints={setCurrentPoints} />
    <Router basename={"/"+domain}>
      <Routes >
 <Route path="/" element={render}></Route>
  <Route path="/point/:pointId" element={render}></Route>
          <Route path="*" element={render}></Route>
      </Routes>
  </Router>
      </div>
  );
}

export default App;
