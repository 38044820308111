//для определения языка, если он не указан
export const detectLanguage=()=>
{
let lang='ru' //по-умолчанию русский язык
  if (localStorage.getItem('lang')===null) //язык уже установлен ранее
{ 
const navLang=navigator.language || navigator.userLanguage
    if (navLang.indexOf('en')!==-1) lang='en'
   }
   else lang=localStorage.getItem('lang')
setLanguage(lang)
return lang
}

//для установки языка
export const setLanguage=(lang)=>
{
  if (lang==='en' && typeof window.translate_en!=='undefined') window.translateArr=window.translate_en
  else window.translateArr=undefined
localStorage.setItem('lang',lang)
}

 export const translate=(s)=>
 {
  if (typeof window.translateArr==='undefined') return s //перевод не требуется, язык не установлен, либо такой язык в принципе не найден ранее
window.translateArr.forEach((it)=>
   {
const cur=Object.entries(it)[0];
    if (cur[0]===s) { s=cur[1]; return; }
   })
return s;
 }