function menuReducer(state,action)
{
  switch (action.type) {
    case 'switch_close':
    {
return {...state, isClose:!state.isClose}
     }
    case 'set_close':
    {
return {...state, isClose:action.visible}
     }
    case 'show_point':
    {
return {...state, openPoint:true}
     }
    case 'close_point':
    {
return {...state, openPoint:false}
     }
    case 'show_filters':
    {
return {...state, openFilters:true, openPartners:false}
     }
    case 'show_partners':
    {
return {...state, openFilters:false, openPartners:true}
     }
default: { throw new Error('Unknown action: '+action.type) }
  }
}

export default menuReducer;